import theme from './theme';
import dataConfig from './dataConfig';
import orderPlacementConfig from './orderPlacementConfig';
import dashboardConfig from './dashboardConfig';
import titleConfig from './titleConfig';
import tasks from './columns/tasks';
import jobDetails from './jobDetails';
import pendingAssets from './columns/pendingAssets';
import { DeepPartial } from '@/redux/slices/slice_types';
import { ClientConfig } from '@/__configs/config_types';

const config: DeepPartial<ClientConfig> = {
  clientName: 'BBC',
  // colors/styling throughout the app
  theme,

  // any manipulation of the data as it is retrieved
  dataConfig,

  // customizations on dashboard page
  dashboardConfig,

  orderPlacementConfig,

  titleConfig,
  jobDetails,
  bulkSearch: false,
  notifications: true,

  // customization of table columns for each page
  columns: {
    tasks,
    pendingAssets
  },

  // versionColors: {},

  userColors: [
    '#8E8DDC',
    '#92AD7B',
    '#0c61c8',
    '#219738',
    '#e5802e',
    '#dd3322',
    '#cc4466',
    '#5b0aaf',
    '#3a379d',
    '#008811',
    '#138592',
    '#996688',
    '#667799',
    '#4477bb',
    '#4466ff'
  ],
  versionColors: {
    edit: '#B16BE9',
    worldwide: '#5566ee'
  },
  enableUserDocumentation: true,
  unfilteredReports: true

  // forces all job labels to use the same color, the one specified here
  // jobTypeLabelColor: 'blue',
  // taskTypeLabelColor: 'lavender'
  // disableJobDetailProxy: true
};

export default config;
